const interactivemap = [
  {
    id: 1,
    status: "BOOKED",
    villa_number: "13-01-01",
    plot_size_sqm: "413.32",
    gfa_sqm: "227.20",
    floors: "GF+FF",
    gfa_sqm_with_ph: "279.55",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " DETACHED",
    color: "#ff0000c5",
    rotation: "-20deg",
    left: "27.5%",
    typeNumber: "3",
    top: "17%",
  },
  {
    id: 2,
    status: "BOOKED",
    villa_number: "13-01-02",
    plot_size_sqm: "268.95",
    gfa_sqm: "227.20",
    floors: "GF+FF",
    gfa_sqm_with_ph: " 279.55",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " DETACHED",
    color: "#ff0000c5",
    rotation: "17deg",
    left: "34.8%",

    typeNumber: "3",
    top: "19.5%",
  },
  {
    id: 3,
    status: "AVAILABLE",
    villa_number: "13-01-03",
    plot_size_sqm: "213.51",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "17deg",
    left: "40.8%",

    typeNumber: "1",
    top: "21.7%",
  },
  {
    id: 4,
    status: "AVAILABLE",
    villa_number: "13-01-04",
    plot_size_sqm: "213.51",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "17deg",
    left: "46.5%",

    typeNumber: "1",
    top: "23.7%",
  },
  {
    id: 5,
    status: "AVAILABLE",
    villa_number: "13-01-05",
    plot_size_sqm: "213.51",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "17deg",
    left: "52.5%",

    typeNumber: "1",
    top: "26%",
  },
  {
    id: 6,
    status: "AVAILABLE",
    villa_number: "13-01-06",
    plot_size_sqm: "213.51",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#008000b9",
    rotation: "17deg",
    left: "58.5%",

    typeNumber: "1",
    top: "28.3%",
  },
  {
    id: 7,
    status: "BOOKED",
    villa_number: "13-01-07",
    plot_size_sqm: "268.13",
    gfa_sqm: "227.20",
    floors: "GF+FF",
    gfa_sqm_with_ph: "279.55",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "DETACHED",
    color: "#ff0000c5",
    rotation: "17deg",
    left: "64.3%",

    typeNumber: "3",
    top: "30.5%",
  },
  {
    id: 8,
    status: "BOOKED",
    villa_number: "13-01-08",
    plot_size_sqm: "267.90",
    gfa_sqm: "227.20",
    floors: "GF+FF",
    gfa_sqm_with_ph: "279.55",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "DETACHED",
    color: "#ff0000c5",
    rotation: "17deg",
    left: "62.5%",

    typeNumber: "3",
    top: "38.2%",
  },
  {
    id: 9,
    status: "AVAILABLE",
    villa_number: "13-01-09",
    plot_size_sqm: "213.51",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#008000b9",
    rotation: "17deg",
    left: "56.5%",

    typeNumber: "1",
    top: "36%",
  },
  {
    id: 10,
    status: "BOOKED",
    villa_number: "13-01-10",
    plot_size_sqm: "213.51",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#ff0000c5",
    rotation: "17deg",
    left: "50.5%",

    typeNumber: "1",
    top: "33.8%",
  },
  {
    id: 11,
    status: "BOOKED",
    villa_number: "13-01-11",
    plot_size_sqm: "213.51",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#ff0000c5",
    rotation: "17deg",
    left: "44.3%",

    typeNumber: "1",
    top: "31.5%",
  },
  {
    id: 12,
    status: "AVAILABLE",
    villa_number: "13-01-12",
    plot_size_sqm: "213.51",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#008000b9",
    rotation: "17deg",
    left: "38.5%",

    typeNumber: "1",
    top: "29.2%",
  },
  {
    id: 13,
    status: "BOOKED",
    villa_number: "13-01-13",
    plot_size_sqm: "268.48",
    gfa_sqm: "227.20",
    floors: "GF+FF",
    gfa_sqm_with_ph: " 279.55",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "DETACHED",
    color: "#ff0000c5",
    rotation: "17deg",
    left: "33%",

    typeNumber: "3",
    top: "27%",
  },

  {
    id: 14,
    status: "BOOKED",
    villa_number: "13-02-01",
    plot_size_sqm: "256.71",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "DETACHED",
    color: "#ff0000c5",
    rotation: "-20deg",
    left: "77%",

    typeNumber: "2",
    top: "70%",
  },
  {
    id: 15,
    status: "BOOKED",
    villa_number: "13-02-02",
    plot_size_sqm: "246.73",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "DETACHED",
    color: "#ff0000c5",
    rotation: "-20deg",
    left: "74.7%",

    typeNumber: "2",
    top: "63%",
  },
  {
    id: 16,
    status: "BOOKED",
    villa_number: "13-02-03",
    plot_size_sqm: "329.83",
    gfa_sqm: "227.20",
    floors: "GF+FF",
    gfa_sqm_with_ph: "279.55",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "DETACHED",
    color: "#ff0000c5",
    rotation: "-20deg",
    left: "72.5%",

    typeNumber: "3",
    top: "56%",
  },
  {
    id: 17,
    status: "AVAILABLE",
    villa_number: "13-02-04",
    plot_size_sqm: "213.48",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#008000b9",
    rotation: "17deg",
    left: "73.4%",

    typeNumber: "1",
    top: "47.1%",
  },
  {
    id: 18,
    status: "AVAILABLE",
    villa_number: "13-02-05",
    plot_size_sqm: "213.34",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#008000b9",
    rotation: "17deg",
    left: "75%",

    typeNumber: "1",
    top: "40.1%",
  },

  {
    id: 19,
    status: "BOOKED",
    villa_number: "13-02-06",
    plot_size_sqm: "299.41",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "DETACHED",
    color: "#ff0000c5",
    rotation: "17deg",
    left: "76.5%",

    typeNumber: "2",
    top: "32.7%",
  },

  {
    id: 20,
    status: "BOOKED",
    villa_number: "13-02-07",
    plot_size_sqm: "438.20",
    gfa_sqm: "227.20",
    floors: "GF+FF",
    gfa_sqm_with_ph: "279.55",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "DETACHED",
    color: "#ff0000c5",
    rotation: "-2deg",
    left: "82.4%",

    typeNumber: "3",
    top: "41.2%",
  },

  {
    id: 21,
    status: "BOOKED",
    villa_number: "13-02-08",
    plot_size_sqm: "263.11",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#ff0000c5",
    rotation: "-2deg",
    left: "82.4%",

    typeNumber: "1",
    top: "48.5%",
  },
  {
    id: 22,
    status: "BOOKED",
    villa_number: "13-02-09",
    plot_size_sqm: "278.92",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#ff0000c5",
    rotation: "-2deg",
    left: "82.6%",

    typeNumber: "1",
    top: "55.5%",
  },
  {
    id: 23,
    status: "BOOKED",
    villa_number: "13-02-10",
    plot_size_sqm: "382.92",
    gfa_sqm: "227.20",
    floors: "GF+FF",
    gfa_sqm_with_ph: "279.55",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "DETACHED",
    color: "#ff0000c5",
    rotation: "-2deg",
    left: "82.8%",

    typeNumber: "3",
    top: "63%",
  },

  {
    id: 24,
    status: "AVAILABLE",
    villa_number: "13-03-01",
    plot_size_sqm: "239.83",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "45.6%",

    typeNumber: "1",
    top: "78%",
  },
  {
    id: 25,
    status: "AVAILABLE",
    villa_number: "13-03-02",
    plot_size_sqm: "213.43",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "51.5%",

    typeNumber: "1",
    top: "75%",
  },
  {
    id: 26,
    status: "AVAILABLE",
    villa_number: "13-03-03",
    plot_size_sqm: "213.43",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "57%",

    typeNumber: "1",
    top: "72.5%",
  },
  {
    id: 27,
    status: "AVAILABLE",
    villa_number: "13-03-04",
    plot_size_sqm: "241.55",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "63%",

    typeNumber: "1",
    top: "70%",
  },
  {
    id: 28,
    status: "BOOKED",
    villa_number: "13-03-05",
    plot_size_sqm: "241.55",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "60.8%",

    typeNumber: "1",
    top: "63%",
  },
  {
    id: 29,
    status: "AVAILABLE",
    villa_number: "13-03-06",
    plot_size_sqm: "213.43",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "55%",

    typeNumber: "1",
    top: "66%",
  },
  {
    id: 30,
    status: "AVAILABLE",
    villa_number: "13-03-07",
    plot_size_sqm: "213.43",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "49%",

    typeNumber: "1",
    top: "68.5%",
  },
  {
    id: 31,
    status: "BOOKED",
    villa_number: "13-03-08",
    plot_size_sqm: "239.83",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "43.5%",

    typeNumber: "1",
    top: "71%",
  },
  {
    id: 32,
    status: "BOOKED",
    villa_number: "13-04-01",
    plot_size_sqm: "292.2101",
    gfa_sqm: "227.20",
    floors: "GF+FF",
    gfa_sqm_with_ph: "279.55",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "DETACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "36%",

    typeNumber: "3",
    top: "88.5%",
  },
  {
    id: 33,
    status: "BOOKED",
    villa_number: "13-04-02",
    plot_size_sqm: "268.9334",
    gfa_sqm: "227.20",
    floors: "GF+FF",
    gfa_sqm_with_ph: "279.55",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "DETACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "34%",

    typeNumber: "3",
    top: "82%",
  },
  {
    id: 34,
    status: "AVAILABLE",
    villa_number: "13-04-03",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "32%",

    typeNumber: "1",
    top: "75%",
  },
  {
    id: 35,
    status: "AVAILABLE",
    villa_number: "13-04-04",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "29.5%",

    typeNumber: "1",
    top: "68%",
  },
  {
    id: 36,
    status: "BOOKED",
    villa_number: "13-04-05",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "ATTACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "27%",

    typeNumber: "1",
    top: "61%",
  },
  {
    id: 37,
    status: "BOOKED",
    villa_number: "13-04-06",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "25%",

    typeNumber: "1",
    top: "54%",
  },
  {
    id: 38,
    status: "BOOKED",
    villa_number: "13-04-07",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "23%",

    typeNumber: "1",
    top: "47.8%",
  },
  {
    id: 39,
    status: "BOOKED",
    villa_number: "13-04-08",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "21%",

    typeNumber: "1",
    top: "40.8%",
  },
  {
    id: 40,
    status: "AVAILABLE",
    villa_number: "13-04-09",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "19%",

    typeNumber: "1",
    top: "33.8%",
  },
  {
    id: 41,
    status: "AVAILABLE",
    villa_number: "13-04-10",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "17%",

    typeNumber: "1",
    top: "26.8%",
  },
  {
    id: 42,
    status: "BOOKED",
    villa_number: "13-04-11",
    plot_size_sqm: "246.9328",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " DETACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "15%",

    typeNumber: "2",
    top: "19.8%",
  },
  {
    id: 43,
    status: "BOOKED",
    villa_number: "13-04-12",
    plot_size_sqm: "335.6285",
    gfa_sqm: "227.20",
    floors: "GF+FF",
    gfa_sqm_with_ph: "279.55",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "DETACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "12.7%",

    typeNumber: "3",
    top: "12.8%",
  },
  {
    id: 44,
    status: "BOOKED",
    villa_number: "13-04-13",
    plot_size_sqm: "289.1097",
    gfa_sqm: "227.20",
    floors: "GF+FF",
    gfa_sqm_with_ph: "279.55",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: "DETACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "5%",

    typeNumber: "3",
    top: "8.5%",
  },
  {
    id: 45,
    status: "BOOKED",
    villa_number: "13-04-14",
    plot_size_sqm: "246.6863",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " DETACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "7%",

    typeNumber: "2",
    top: "15.5%",
  },
  {
    id: 46,
    status: "BOOKED",
    villa_number: "13-04-15",
    plot_size_sqm: "246.6863",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " DETACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "8.9%",

    typeNumber: "2",
    top: "22%",
  },
  {
    id: 47,
    status: "AVAILABLE",
    villa_number: "13-04-16",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "11%",

    typeNumber: "1",
    top: "29%",
  },
  {
    id: 48,
    status: "AVAILABLE",
    villa_number: "13-04-17",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "13%",

    typeNumber: "1",
    top: "36%",
  },
  {
    id: 49,
    status: "AVAILABLE",
    villa_number: "13-04-18",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "15%",

    typeNumber: "1",
    top: "43%",
  },
  {
    id: 50,
    status: "AVAILABLE",
    villa_number: "13-04-19",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "17%",

    typeNumber: "1",
    top: "50%",
  },
  {
    id: 51,
    status: "AVAILABLE",
    villa_number: "13-04-20",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "19%",

    typeNumber: "1",
    top: "57%",
  },
  {
    id: 52,
    status: "AVAILABLE",
    villa_number: "13-04-21",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "21%",

    typeNumber: "1",
    top: "64%",
  },
  {
    id: 53,
    status: "AVAILABLE",
    villa_number: "13-04-22",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "23.5%",

    typeNumber: "1",
    top: "70.5%",
  },
  {
    id: 54,
    status: "AVAILABLE",
    villa_number: "13-04-23",
    plot_size_sqm: "213.4478",
    gfa_sqm: "205.10",
    floors: "GF+FF",
    gfa_sqm_with_ph: "249.05",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " ATTACHED",
    color: "#008000b9",
    rotation: "-21deg",
    left: "25.8%",

    typeNumber: "1",
    top: "78%",
  },
  {
    id: 55,
    status: "BOOKED",
    villa_number: "13-04-24",
    plot_size_sqm: "268.9719",
    gfa_sqm: "227.20",
    floors: "GF+FF",
    gfa_sqm_with_ph: "279.55",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " DETACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "27.8%",

    typeNumber: "3",
    top: "84.6%",
  },
  {
    id: 56,
    status: "BOOKED",
    villa_number: "13-04-25",
    plot_size_sqm: "355.1872",
    gfa_sqm: "227.20",
    floors: "GF+FF",
    gfa_sqm_with_ph: "279.55",
    floors_2: "GF+FF+PH",
    number_of_rooms: "4 Bedrooms",
    type: " DETACHED",
    color: "#ff0000c5",
    rotation: "-21deg",
    left: "30%",

    typeNumber: "3",
    top: "91%",
  },
];
export default interactivemap;
