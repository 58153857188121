import React from "react";
import { PiMapPinThin } from "react-icons/pi";
import i18next from "i18next";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";

function Amenities() {
  const { t } = useTranslation();

  const currentLanguageCode = cookies.get("i18next") || "en";
  return (
    <div
      className={`flex font-[500] justify-center  items-center flex-col px-[3%] py-[3%] ${
        currentLanguageCode === "ar" && "text-right"
      }`}
    >
      <h1
        className={`text-[2.5vw] w-[50%] xl:w-[100%] xl:text-[35px] font-[cairo] ${
          currentLanguageCode === "ar" ? "text-right" : "text-left"
        }`}
      >
        {t("amenities.title")}
      </h1>

      <ul className="w-[50%] py-[2%] xl:w-[100%] flex justify-between gap-[20px] text-[20px] md:text-[18px] flex-col">
        <li className={`flex font-[500] ${currentLanguageCode==="ar" && "flex-row-reverse"}`}>
          <span>
            <PiMapPinThin className="text-[28px] text-[#7a2b1d] font-[800]" />
          </span>
          {t("amenities.option1")}
        </li>
        <li className={`flex font-[500] ${currentLanguageCode==="ar" && "flex-row-reverse"}`}>
          <span>
            <PiMapPinThin className="text-[28px] text-[#7a2b1d] font-[800]" />
          </span>
          {t("amenities.option2")}
        </li>
        <li className={`flex font-[500] ${currentLanguageCode==="ar" && "flex-row-reverse"}`}>
          <span>
            <PiMapPinThin className="text-[28px] text-[#7a2b1d] font-[800]" />
          </span>
          {t("amenities.option3")}
        </li>
        <li className={`flex font-[500] ${currentLanguageCode==="ar" && "flex-row-reverse"}`}>
          <span>
            <PiMapPinThin className="text-[28px] text-[#7a2b1d] font-[800]" />
          </span>
          {t("amenities.option4")}
        </li>
        <li className={`flex font-[500] ${currentLanguageCode==="ar" && "flex-row-reverse"}`}>
          <span>
            <PiMapPinThin className="text-[28px] text-[#7a2b1d] font-[800]" />
          </span>
          {t("amenities.option5")}
        </li>
        <li className={`flex font-[500] ${currentLanguageCode==="ar" && "flex-row-reverse"}`}>
          <span>
            <PiMapPinThin className="text-[28px] text-[#7a2b1d] font-[800]" />
          </span>
          {t("amenities.option6")}
        </li>
        <li className={`flex font-[500] ${currentLanguageCode==="ar" && "flex-row-reverse"}`}>
          <span>
            <PiMapPinThin className="text-[28px] text-[#7a2b1d] font-[800]" />
          </span>
          {t("amenities.option7")}
        </li>
        <li className={`flex font-[500] ${currentLanguageCode==="ar" && "flex-row-reverse"}`}>
          <span>
            <PiMapPinThin className="text-[28px] text-[#7a2b1d] font-[800]" />
          </span>
          {t("amenities.option8")}
        </li>
        <li className={`flex font-[500] ${currentLanguageCode==="ar" && "flex-row-reverse"}`}>
          <span>
            <PiMapPinThin className="text-[28px] text-[#7a2b1d] font-[800]" />
          </span>
          {t("amenities.option9")}
        </li>
        <li className={`flex font-[500] ${currentLanguageCode==="ar" && "flex-row-reverse"}`}>
          <span>
            <PiMapPinThin className="text-[28px] text-[#7a2b1d] font-[800]" />
          </span>
          {t("amenities.option10")}
        </li>
      </ul>
    </div>
  );
}

export default Amenities;
