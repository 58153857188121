import React, { useEffect, useRef, useState } from "react";
import Video from "../Components/Home/Video";
import OurCommunity from "../Components/Home/OurCommunity/OurCommunity";
import { Link } from "react-router";
import { ImWhatsapp } from "react-icons/im";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
function Home() {
  const [showContact, setShowContact] = useState(false);
  const communityRef = useRef(null);
  const { t } = useTranslation();
  const scrollToCommunity = () => {
    if (communityRef.current) {
      communityRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const currentLanguageCode = cookies.get("i18next") || "en";
  return (
    <div>
      <Video scrollToCommunity={scrollToCommunity} />

      <div ref={communityRef}>
        <OurCommunity />
      </div>
    </div>
  );
}

export default Home;
