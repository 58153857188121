import React, { useEffect, useState } from "react";
import headerContactUs from "../images/map/Slide-6.webp";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; // Optional: To include default styling for PhoneInput
import i18next from "i18next";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
function ContactUs() {
  const [phoneValue, setPhoneValue] = useState(""); // State for phone number
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const currentLanguageCode = cookies.get("i18next") || "en";
  return (
    <>
      <div className="flex justify-center items-center relative font-[cairo] w-fit font-[300] tracking-widest">
        <div className="md:h-[50vh]">
          <img
            alt=""
            className="w-[100%] h-[100%] object-cover"
            src={headerContactUs}
          />
        </div>
        <div className="absolute flex justify-center items-center flex-col text-center w-fit top-[40%]">
          <p className="text-[white] w-[80%] text-[3vw] md:text-[20px] md:w-[90%] bg-[#202020c2]">
            {t("contact.title")}
          </p>
          <p className="text-[2vw] md:text-[12px] text-[white] bg-[#202020c2] font-[100] px-[1%]">
            {t("contact.subtitle")}
          </p>
        </div>
      </div>
      <div
        className={`w-[100%] lg:flex-col-reverse lg:gap-[10px] flex ${
          currentLanguageCode === "ar" && "flex-row-reverse"
        }  justify-between  items-center p-[3%] sm:p-[1%]`}
      >
        <div className="w-[45%] h-[80vh] lg:h-[50vh] lg:w-[100%] ">
          <iframe
            className="object-contain rounded-[20px] h-[100%] w-[100%] "
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3656.629375622113!2d58.34763100000001!3d23.581751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjPCsDM0JzU0LjMiTiA1OMKwMjAnNTEuNSJF!5e0!3m2!1sen!2slb!4v1737493597068!5m2!1sen!2slb"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Map"
          ></iframe>
        </div>

        <div
          className={`${
            currentLanguageCode === "ar" && "text-right"
          }  w-[50%] py-[3%] lg:w-[100%] bg-[#000000d3] flex justify-center items-center h-[100%]`}
        >
          <form className="flex justify-between gap-[15px] xl:gap-[10px]  p-[5%] flex-col">
            <h1 className="text-[3vw] lg:text-[5vw] md:text-[6vw] font-[cairo] font-[300] text-white">
              {t("contact.form.title")}
            </h1>
            <div className="flex flex-col font-[cairo]">
              <label
                htmlFor="fullName1"
                className={`mb-[5px] text-[18px] font-[cairo] text-[white] ${
                  currentLanguageCode === "ar" &&
                  "flex flex-row-reverse gap-[5px]"
                }`}
              >
                {t("contact.form.name")}{" "}
                <span className="text-[#ecc55e]">*</span>
              </label>
              <input
                id="fullName1"
                required
                className="border-[1px] p-[10px]"
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="email"
                className={`mb-[5px] text-[18px] font-[cairo] text-[white] ${
                  currentLanguageCode === "ar" &&
                  "flex flex-row-reverse gap-[5px]"
                }`}
              >
                {t("contact.form.email")}
                <span className="text-[#ecc55e]">*</span>
              </label>
              <input id="email" required className="border-[1px] p-[10px]" />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="phone"
                className={`mb-[5px] text-[18px] font-[cairo] text-[white] ${
                  currentLanguageCode === "ar" &&
                  "flex flex-row-reverse gap-[5px]"
                }`}
              >
                {t("contact.form.phone")}
                <span className="text-[#ecc55e]">*</span>
              </label>
              <PhoneInput
                id="phone"
                international
                defaultCountry="OM"
                value={phoneValue}
                onChange={setPhoneValue}
                className="border-[1px] p-[10px]"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="propertyType"
                className={`mb-[5px] text-[18px] font-[cairo] text-[white] ${
                  currentLanguageCode === "ar" &&
                  "flex flex-row-reverse gap-[5px]"
                }`}
              >
                {t("contact.form.type.title")}
                <span className="text-[#ecc55e]">*</span>
              </label>
              <form className="flex flex-row flex-wrap gap-[10px]">
                <div
                  className={`flex items-center w-[250px] space-x-3  ${
                    currentLanguageCode === "ar"
                      ? "flex-row-reverse gap-[5px]"
                      : "flex-row"
                  }`}
                >
                  <input
                    id="villa"
                    name="propertyType"
                    value="villa"
                    type="checkbox"
                    className="w-6 h-6 cursor-pointer accent-[white] rounded focus:ring-[#ecc55e]"
                  />
                  <label htmlFor="villa" className="text-white text-md">
                    {t("navbar.community.villas")}
                  </label>
                </div>

                <div
                  className={`flex items-center w-[250px] space-x-3  ${
                    currentLanguageCode === "ar"
                      ? "flex-row-reverse gap-[5px]"
                      : "flex-row"
                  }`}
                >
                  <input
                    id="townHouses"
                    name="propertyType"
                    value="townHouses"
                    type="checkbox"
                    className="w-6 h-6 cursor-pointer accent-[white] rounded focus:ring-[#ecc55e]"
                  />
                  <label htmlFor="townHouses" className="text-white text-md">
                    {t("navbar.community.ahed")}
                  </label>
                </div>

                <div
                  className={`flex items-center w-[250px] space-x-3  ${
                    currentLanguageCode === "ar"
                      ? "flex-row-reverse gap-[5px]"
                      : "flex-row"
                  }`}
                >
                  <input
                    id="oneBedroomApartment"
                    name="propertyType"
                    value="oneBedroomApartment"
                    type="checkbox"
                    className="w-6 h-6 cursor-pointer accent-[white] rounded focus:ring-[#ecc55e]"
                  />
                  <label
                    htmlFor="oneBedroomApartment"
                    className="text-white text-md"
                  >
                    {t("contact.form.type.option1")}
                  </label>
                </div>

                <div
                  className={`flex items-center w-[250px] space-x-3  ${
                    currentLanguageCode === "ar"
                      ? "flex-row-reverse gap-[5px]"
                      : "flex-row"
                  }`}
                >
                  <input
                    id="twoBedroomApartment"
                    name="propertyType"
                    value="twoBedroomApartment"
                    type="checkbox"
                    className="w-6 h-6 cursor-pointer accent-[white] rounded focus:ring-[#ecc55e]"
                  />
                  <label
                    htmlFor="twoBedroomApartment"
                    className="text-white text-md"
                  >
                    {t("contact.form.type.option2")}
                  </label>
                </div>

                <div
                  className={`flex items-center w-[250px] space-x-3  ${
                    currentLanguageCode === "ar"
                      ? "flex-row-reverse gap-[5px]"
                      : "flex-row"
                  }`}
                >
                  <input
                    id="threeBedroom"
                    name="propertyType"
                    value="threeBedroom"
                    type="checkbox"
                    className="w-6 h-6 cursor-pointer accent-[white] rounded focus:ring-[#ecc55e]"
                  />
                  <label htmlFor="threeBedroom" className="text-white text-md">
                    {t("contact.form.type.option3")}
                  </label>
                </div>

                <div
                  className={`flex items-center w-[250px] space-x-3  ${
                    currentLanguageCode === "ar"
                      ? "flex-row-reverse gap-[5px]"
                      : "flex-row"
                  }`}
                >
                  <input
                    id="commercialSpace"
                    name="propertyType"
                    value="commercialSpace"
                    type="checkbox"
                    className="w-6 h-6 cursor-pointer accent-[white] rounded focus:ring-[#ecc55e]"
                  />
                  <label
                    htmlFor="commercialSpace"
                    className="text-white text-md"
                  >
                    {t("contact.form.type.option4")}
                  </label>
                </div>
              </form>
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="message"
                className={`mb-[5px] text-[18px] font-[cairo] text-[white] ${
                  currentLanguageCode === "ar" &&
                  "flex flex-row-reverse gap-[5px]"
                }`}
              >
                {t("contact.form.message")}
                <span className="text-[#ecc55e]">*</span>
              </label>
              <textarea
                id="message"
                required
                className="border-[1px] p-[10px] h-[10vh]"
              />
            </div>

            <div
              className={`flex  ${
                currentLanguageCode === "ar"
                  ? "justify-start items-start"
                  : "justify-end items-end"
              }`}
            >
              <button
                type="submit"
                className="px-[4%] py-[1%] border-[1px] hover:bg-[WHITE] text-[white] hover:text-[gray]"
              >
                {t("contact.form.submit")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
