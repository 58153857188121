import React from "react";
import "./ourCommunity.css";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";

function OurCommunity() {
  const { t } = useTranslation();
  return (
    <div className="card h-[100vh] text-[50px] 2xl:text-[40px] lg:text-[25px] md:text-[18px] text-end lg:h-[70vh] md:h-[50vh] font-[cairo]">
      <Link to="/our-community/azha" className="card-item residencial">
        <span className="card-text lg:right-[60px] right-[100px] w-[55vw] 2xl:w-[75vw] xl:w-[85vw] md:w-[90vw] py-[0.5em] pr-[50%] sm:w-[120vw]">{t("navbar.community.residential")}</span>
      </Link>
      <Link to="/our-community/ahed" className="card-item ahed">
        <span className="card-text lg:right-[60px] right-[100px] w-[55vw] 2xl:w-[75vw] xl:w-[85vw] md:w-[90vw] py-[0.5em] pr-[50%] sm:w-[120vw] ">{t("navbar.community.ahed")}</span>
      </Link>
      <p className="card-item villas">
        <span className="card-text lg:right-[60px] right-[100px] w-[55vw] 2xl:w-[75vw] xl:w-[85vw] md:w-[90vw] py-[0.5em] pr-[50%] sm:w-[120vw]">{t("navbar.community.villas")}</span>
      </p>
    </div>
  );
}

export default OurCommunity;
