const communityMap = [
  {
    floorData: {
      F5: [
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 505",
          top: "8%",
          left: "11.5%",
          topXl: "1%",
          leftXl: "1%",
          height: "40%",
          width: "11.5%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 504",
          top: "8%",
          left: "26%",
          topXl: "1%",
          leftXl: "21.5%",
          height: "40%",
          width: "11%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 506",
          bottom: "12%",
          left: "11.5%",
          topXl: "35%",
          leftXl: "1%",
          height: "40%",
          width: "11.5%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-112 Sq.m",
          unit: "B1 - 507",
          bottom: "11%",
          left: "22.5%",
          topXl: "50%",
          leftXl: "17%",
          height: "22%",
          width: "22%",
          heightXl: "19%",
          widthXl: "31%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B1 - 501",
          bottom: "11%",
          right: "19%",
          topXl: "48%",
          leftXl: "67%",
          height: "25.5%",
          width: "23%",
          heightXl: "23%",
          widthXl: "33%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B1 - 502",
          bottom: "36.8%",
          right: "19%",
          topXl: "27%",
          leftXl: "67%",
          height: "25.5%",
          width: "23%",
          heightXl: "23%",
          widthXl: "33%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-141 Sq.m",
          unit: "B1 - 503",
          bottom: "40.5%",
          right: "42%",
          topXl: "27%",
          leftXl: "22%",
          height: "22%",
          width: "32%",
          heightXl: "20%",
          widthXl: "45%",
          bg: "#008000b4",
        },
      ],
      F4: [
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 405",
          top: "8%",
          left: "11.5%",
          topXl: "1%",
          leftXl: "1%",
          height: "40%",
          width: "11.5%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 404",
          top: "8%",
          left: "26%",
          topXl: "1%",
          leftXl: "21.5%",
          height: "40%",
          width: "11%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 406",
          bottom: "12%",
          left: "11.5%",
          topXl: "35%",
          leftXl: "1%",
          height: "40%",
          width: "11.5%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-112 Sq.m",
          unit: "B1 - 407",
          bottom: "11%",
          left: "22.5%",
          topXl: "50%",
          leftXl: "17%",
          height: "22%",
          width: "22%",
          heightXl: "19%",
          widthXl: "31%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B1 - 401",
          bottom: "11%",
          right: "19%",
          topXl: "48%",
          leftXl: "67%",
          height: "25.5%",
          width: "23%",
          heightXl: "23%",
          widthXl: "33%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B1 - 402",
          bottom: "36.8%",
          right: "19%",
          topXl: "27%",
          leftXl: "67%",
          height: "25.5%",
          width: "23%",
          heightXl: "23%",
          widthXl: "33%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-141 Sq.m",
          unit: "B1 - 403",
          bottom: "40.5%",
          right: "42%",
          topXl: "27%",
          leftXl: "22%",
          height: "22%",
          width: "32%",
          heightXl: "20%",
          widthXl: "45%",
          bg: "#008000b4",
        },
      ],
      F3: [
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 305",
          top: "8%",
          left: "11.5%",
          topXl: "1%",
          leftXl: "1%",
          height: "40%",
          width: "11.5%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 304",
          top: "8%",
          left: "26%",
          topXl: "1%",
          leftXl: "21.5%",
          height: "40%",
          width: "11%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 306",
          bottom: "12%",
          left: "11.5%",
          topXl: "35%",
          leftXl: "1%",
          height: "40%",
          width: "11.5%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-112 Sq.m",
          unit: "B1 - 307",
          bottom: "11%",
          left: "22.5%",
          topXl: "50%",
          leftXl: "17%",
          height: "22%",
          width: "22%",
          heightXl: "19%",
          widthXl: "31%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B1 - 301",
          bottom: "11%",
          right: "19%",
          topXl: "48%",
          leftXl: "67%",
          height: "25.5%",
          width: "23%",
          heightXl: "23%",
          widthXl: "33%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B1 - 302",
          bottom: "36.8%",
          right: "19%",
          topXl: "27%",
          leftXl: "67%",
          height: "25.5%",
          width: "23%",
          heightXl: "23%",
          widthXl: "33%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-141 Sq.m",
          unit: "B1 - 303",
          bottom: "40.5%",
          right: "42%",
          topXl: "27%",
          leftXl: "22%",
          height: "22%",
          width: "32%",
          heightXl: "20%",
          widthXl: "45%",
          bg: "#008000b4",
        },
      ],
      F2: [
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 205",
          top: "8%",
          left: "11.5%",
          topXl: "1%",
          leftXl: "1%",
          height: "40%",
          width: "11.5%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 204",
          top: "8%",
          left: "26%",
          topXl: "1%",
          leftXl: "21.5%",
          height: "40%",
          width: "11%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 206",
          bottom: "12%",
          left: "11.5%",
          topXl: "35%",
          leftXl: "1%",
          height: "40%",
          width: "11.5%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-112 Sq.m",
          unit: "B1 - 207",
          bottom: "11%",
          left: "22.5%",
          topXl: "50%",
          leftXl: "17%",
          height: "22%",
          width: "22%",
          heightXl: "19%",
          widthXl: "31%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B1 - 201",
          bottom: "11%",
          right: "19%",
          topXl: "48%",
          leftXl: "67%",
          height: "25.5%",
          width: "23%",
          heightXl: "23%",
          widthXl: "33%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B1 - 202",
          bottom: "36.8%",
          right: "19%",
          topXl: "27%",
          leftXl: "67%",
          height: "25.5%",
          width: "23%",
          heightXl: "23%",
          widthXl: "33%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-141 Sq.m",
          unit: "B1 - 203",
          bottom: "40.5%",
          right: "42%",
          topXl: "27%",
          leftXl: "22%",
          height: "22%",
          width: "32%",
          heightXl: "20%",
          widthXl: "45%",
          bg: "#008000b4",
        },
      ],
      F1: [
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 105",
          top: "8%",
          left: "11.5%",
          topXl: "1%",
          leftXl: "1%",
          height: "40%",
          width: "11.5%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 104",
          top: "8%",
          left: "26%",
          topXl: "1%",
          leftXl: "21.5%",
          height: "40%",
          width: "11%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B1 - 106",
          bottom: "12%",
          left: "11.5%",
          topXl: "35%",
          leftXl: "1%",
          height: "40%",
          width: "11.5%",
          heightXl: "35%",
          widthXl: "16%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-112 Sq.m",
          unit: "B1 - 107",
          bottom: "11%",
          left: "22.5%",
          topXl: "50%",
          leftXl: "17%",
          height: "22%",
          width: "22%",
          heightXl: "19%",
          widthXl: "31%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B1 - 101",
          bottom: "11%",
          right: "19%",
          topXl: "48%",
          leftXl: "67%",
          height: "25.5%",
          width: "23%",
          heightXl: "23%",
          widthXl: "33%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B1 - 102",
          bottom: "36.8%",
          right: "19%",
          topXl: "27%",
          leftXl: "67%",
          height: "25.5%",
          width: "23%",
          heightXl: "23%",
          widthXl: "33%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-141 Sq.m",
          unit: "B1 - 103",
          bottom: "40.5%",
          right: "42%",
          topXl: "27%",
          leftXl: "22%",
          height: "22%",
          width: "32%",
          heightXl: "20%",
          widthXl: "45%",
          bg: "#008000b4",
        },
      ],
    },
  },
  {
    floorData2: {
      F6: [
        {
          room: "3 BR-137 Sq.m",
          unit: "B2 - 603",
          top: "12%",
          left: "7%",
          height: "40%",
          width: "35%",
          topXl: "10%",
          leftXl: "6%",
          heightXl: "28%",
          widthXl: "41%",
          bg: "#008000b4",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B2 - 602",
          top: "12%",
          right: "15%",
          height: "40%",
          width: "28%",
          topXl: "10%",
          leftXl: "60%",
          heightXl: "30%",
          widthXl: "35%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B2 - 601",
          bottom: "10%",
          right: "15%",
          height: "40%",
          width: "28%",
          topXl: "40%",
          leftXl: "60%",
          heightXl: "30%",
          widthXl: "35%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B2 - 604",
          bottom: "10%",
          height: "40%",
          width: "35%",
          left: "7%",
          topXl: "40%",
          leftXl: "7%",
          heightXl: "28%",
          widthXl: "41%",
          bg: "#008000b4",
        },
      ],
      F5: [
        {
          room: "3 BR-137 Sq.m",
          unit: "B2 - 503",
          top: "12%",
          left: "7%",
          height: "40%",
          width: "35%",
          topXl: "10%",
          leftXl: "6%",
          heightXl: "28%",
          widthXl: "41%",
          bg: "#008000b4",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B2 - 502",
          top: "12%",
          right: "15%",
          height: "40%",
          width: "28%",
          topXl: "10%",
          leftXl: "60%",
          heightXl: "30%",
          widthXl: "35%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B2 - 501",
          bottom: "10%",
          right: "15%",
          height: "40%",
          width: "28%",
          topXl: "40%",
          leftXl: "60%",
          heightXl: "30%",
          widthXl: "35%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B2 - 504",
          bottom: "10%",
          height: "40%",
          width: "35%",
          left: "7%",
          topXl: "40%",
          leftXl: "7%",
          heightXl: "28%",
          widthXl: "41%",
          bg: "#008000b4",
        },
      ],
      F4: [
        {
          room: "3 BR-137 Sq.m",
          unit: "B2 - 403",
          top: "12%",
          left: "7%",
          height: "40%",
          width: "35%",
          topXl: "10%",
          leftXl: "6%",
          heightXl: "28%",
          widthXl: "41%",
          bg: "#008000b4",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B2 - 402",
          top: "12%",
          right: "15%",
          height: "40%",
          width: "28%",
          topXl: "10%",
          leftXl: "60%",
          heightXl: "30%",
          widthXl: "35%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B2 - 401",
          bottom: "10%",
          right: "15%",
          height: "40%",
          width: "28%",
          topXl: "40%",
          leftXl: "60%",
          heightXl: "30%",
          widthXl: "35%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B2 - 404",
          bottom: "10%",
          height: "40%",
          width: "35%",
          left: "7%",
          topXl: "40%",
          leftXl: "7%",
          heightXl: "28%",
          widthXl: "41%",
          bg: "#008000b4",
        },
      ],
      F3: [
        {
          room: "3 BR-137 Sq.m",
          unit: "B2 - 303",
          top: "12%",
          left: "7%",
          height: "40%",
          width: "35%",
          topXl: "10%",
          leftXl: "6%",
          heightXl: "28%",
          widthXl: "41%",
          bg: "#008000b4",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B2 - 302",
          top: "12%",
          right: "15%",
          height: "40%",
          width: "28%",
          topXl: "10%",
          leftXl: "60%",
          heightXl: "30%",
          widthXl: "35%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B2 - 301",
          bottom: "10%",
          right: "15%",
          height: "40%",
          width: "28%",
          topXl: "40%",
          leftXl: "60%",
          heightXl: "30%",
          widthXl: "35%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B2 - 304",
          bottom: "10%",
          height: "40%",
          width: "35%",
          left: "7%",
          topXl: "40%",
          leftXl: "7%",
          heightXl: "28%",
          widthXl: "41%",
          bg: "#008000b4",
        },
      ],
      F2: [
        {
          room: "3 BR-137 Sq.m",
          unit: "B2 - 203",
          top: "12%",
          left: "7%",
          height: "40%",
          width: "35%",
          topXl: "10%",
          leftXl: "6%",
          heightXl: "28%",
          widthXl: "41%",
          bg: "#008000b4",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B2 - 202",
          top: "12%",
          right: "15%",
          height: "40%",
          width: "28%",
          topXl: "10%",
          leftXl: "60%",
          heightXl: "30%",
          widthXl: "35%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B2 - 201",
          bottom: "10%",
          right: "15%",
          height: "40%",
          width: "28%",
          topXl: "40%",
          leftXl: "60%",
          heightXl: "30%",
          widthXl: "35%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B2 - 204",
          bottom: "10%",
          height: "40%",
          width: "35%",
          left: "7%",
          topXl: "40%",
          leftXl: "7%",
          heightXl: "28%",
          widthXl: "41%",
          bg: "#008000b4",
        },
      ],
      F1: [
        {
          room: "3 BR-137 Sq.m",
          unit: "B2 - 103",
          top: "12%",
          left: "7%",
          height: "40%",
          width: "35%",
          topXl: "10%",
          leftXl: "6%",
          heightXl: "28%",
          widthXl: "41%",
          bg: "#008000b4",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B2 - 102",
          top: "12%",
          right: "15%",
          height: "40%",
          width: "28%",
          topXl: "10%",
          leftXl: "60%",
          heightXl: "30%",
          widthXl: "35%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B2 - 101",
          bottom: "10%",
          right: "15%",
          height: "40%",
          width: "28%",
          topXl: "40%",
          leftXl: "60%",
          heightXl: "30%",
          widthXl: "35%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B2 - 104",
          bottom: "10%",
          height: "40%",
          width: "35%",
          left: "7%",
          topXl: "40%",
          leftXl: "7%",
          heightXl: "28%",
          widthXl: "41%",
          bg: "#008000b4",
        },
      ],
    },
  },
  {
    floorData3: {
      F5: [
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 504",
          top: "1%",
          left: "36%",
          topXl: "1%",
          leftXl: "47.5%",
          height: "32%",
          width: "10%",
          heightXl: "27%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 503",
          top: "1%",
          right: "43%",
          topXl: "1%",
          leftXl: "70%",
          height: "32%",
          width: "10%",
          heightXl: "27%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "1 BR-75 Sq.m",
          unit: "B3 - 502",
          bottom: "45%",
          right: "43%",
          topXl: "26%",
          leftXl: "70%",
          height: "25%",
          width: "10%",
          heightXl: "15%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 505",
          bottom: "36%",
          left: "36%",
          topXl: "25%",
          leftXl: "48%",
          height: "32%",
          width: "10%",
          heightXl: "30%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-137 Sq.m",
          unit: "B3 - 501",
          bottom: "5%",
          right: "43%",
          topXl: "40%",
          leftXl: "70%",
          height: "45%",
          width: "10%",
          heightXl: "30%",
          widthXl: "17%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B3 - 506",
          bottom: "26%",
          left: "18%",
          topXl: "40%",
          leftXl: "10%",
          height: "25%",
          width: "20%",
          heightXl: "15%",
          widthXl: "40%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B3 - 507",
          bottom: "5%",
          left: "18%",
          topXl: "56%",
          leftXl: "10%",
          height: "20%",
          width: "20%",
          heightXl: "15%",
          widthXl: "40%",
          bg: "#008000b4",
        },
      ],
      F4: [
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 404",
          top: "1%",
          left: "36%",
          topXl: "1%",
          leftXl: "47.5%",
          height: "32%",
          width: "10%",
          heightXl: "27%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 403",
          top: "1%",
          right: "43%",
          topXl: "1%",
          leftXl: "70%",
          height: "32%",
          width: "10%",
          heightXl: "27%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "1 BR-75 Sq.m",
          unit: "B3 - 402",
           bottom: "45%",
          right: "43%",
          topXl: "26%",
          leftXl: "70%",
          height: "25%",
          width: "10%",
          heightXl: "15%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 405",
          bottom: "36%",
          left: "36%",
          topXl: "25%",
          leftXl: "48%",
          height: "32%",
          width: "10%",
          heightXl: "30%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-137 Sq.m",
          unit: "B3 - 401",
         bottom: "5%",
          right: "43%",
          topXl: "40%",
          leftXl: "70%",
          height: "45%",
          width: "10%",
          heightXl: "30%",
          widthXl: "17%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B3 - 406",
           bottom: "26%",
          left: "18%",
          topXl: "40%",
          leftXl: "10%",
          height: "25%",
          width: "20%",
          heightXl: "15%",
          widthXl: "40%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B3 - 407",
           bottom: "5%",
          left: "18%",
          topXl: "56%",
          leftXl: "10%",
          height: "20%",
          width: "20%",
          heightXl: "15%",
          widthXl: "40%",
          bg: "#008000b4",
        },
      ],
      F3: [
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 304",
           top: "1%",
          left: "36%",
          topXl: "1%",
          leftXl: "47.5%",
          height: "32%",
          width: "10%",
          heightXl: "27%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 303",
         top: "1%",
          right: "43%",
          topXl: "1%",
          leftXl: "70%",
          height: "32%",
          width: "10%",
          heightXl: "27%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "1 BR-75 Sq.m",
          unit: "B3 - 302",
          bottom: "45%",
          right: "43%",
          topXl: "26%",
          leftXl: "70%",
          height: "25%",
          width: "10%",
          heightXl: "15%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 305",
          bottom: "36%",
          left: "36%",
          topXl: "25%",
          leftXl: "48%",
          height: "32%",
          width: "10%",
          heightXl: "30%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-137 Sq.m",
          unit: "B3 - 301",
          bottom: "5%",
          right: "43%",
          topXl: "40%",
          leftXl: "70%",
          height: "45%",
          width: "10%",
          heightXl: "30%",
          widthXl: "17%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B3 - 306",
           bottom: "26%",
          left: "18%",
          topXl: "40%",
          leftXl: "10%",
          height: "25%",
          width: "20%",
          heightXl: "15%",
          widthXl: "40%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B3 - 307",
          bottom: "5%",
          left: "18%",
          topXl: "56%",
          leftXl: "10%",
          height: "20%",
          width: "20%",
          heightXl: "15%",
          widthXl: "40%",
          bg: "#008000b4",
        },
      ],
      F2: [
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 204",
           top: "1%",
          left: "36%",
          topXl: "1%",
          leftXl: "47.5%",
          height: "32%",
          width: "10%",
          heightXl: "27%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 203",
          top: "1%",
          right: "43%",
          topXl: "1%",
          leftXl: "70%",
          height: "32%",
          width: "10%",
          heightXl: "27%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "1 BR-75 Sq.m",
          unit: "B3 - 202",
          bottom: "45%",
          right: "43%",
          topXl: "26%",
          leftXl: "70%",
          height: "25%",
          width: "10%",
          heightXl: "15%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 205",
          bottom: "36%",
          left: "36%",
          topXl: "25%",
          leftXl: "48%",
          height: "32%",
          width: "10%",
          heightXl: "30%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-137 Sq.m",
          unit: "B3 - 201",
          bottom: "5%",
          right: "43%",
          topXl: "40%",
          leftXl: "70%",
          height: "45%",
          width: "10%",
          heightXl: "30%",
          widthXl: "17%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B3 - 206",
           bottom: "26%",
          left: "18%",
          topXl: "40%",
          leftXl: "10%",
          height: "25%",
          width: "20%",
          heightXl: "15%",
          widthXl: "40%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B3 - 207",
           bottom: "5%",
          left: "18%",
          topXl: "56%",
          leftXl: "10%",
          height: "20%",
          width: "20%",
          heightXl: "15%",
          widthXl: "40%",
          bg: "#008000b4",
        },
      ],
      F1: [
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 104",
          top: "1%",
          left: "36%",
          topXl: "1%",
          leftXl: "47.5%",
          height: "32%",
          width: "10%",
          heightXl: "27%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 103",
          top: "1%",
          right: "43%",
          topXl: "1%",
          leftXl: "70%",
          height: "32%",
          width: "10%",
          heightXl: "27%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "1 BR-75 Sq.m",
          unit: "B3 - 102",
           bottom: "45%",
          right: "43%",
          topXl: "26%",
          leftXl: "70%",
          height: "25%",
          width: "10%",
          heightXl: "15%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "2 BR-108 Sq.m",
          unit: "B3 - 105",
         bottom: "36%",
          left: "36%",
          topXl: "25%",
          leftXl: "48%",
          height: "32%",
          width: "10%",
          heightXl: "30%",
          widthXl: "17%",
          bg: "#ff0000a9",
        },
        {
          room: "3 BR-137 Sq.m",
          unit: "B3 - 101",
          bottom: "5%",
          right: "43%",
          topXl: "40%",
          leftXl: "70%",
          height: "45%",
          width: "10%",
          heightXl: "30%",
          widthXl: "17%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B3 - 106",
          bottom: "26%",
          left: "18%",
          topXl: "40%",
          leftXl: "10%",
          height: "25%",
          width: "20%",
          heightXl: "15%",
          widthXl: "40%",
          bg: "#008000b4",
        },
        {
          room: "3 BR-136 Sq.m",
          unit: "B3 - 107",
          bottom: "5%",
          left: "18%",
          topXl: "56%",
          leftXl: "10%",
          height: "20%",
          width: "20%",
          heightXl: "15%",
          widthXl: "40%",
          bg: "#008000b4",
        },
      ],
    },
  },
];
export default communityMap;
