import React from 'react'
import loader from "../../images/sarooj-gif.gif";
function Loader() {
  return (
      <div className='flex justify-center items-center p-[5%] z-[500] w-[100%] h-[100%]'>
          <img  alt='' src={loader} />
    </div>
  )
}

export default Loader