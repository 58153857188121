import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import data from "./community.js";
import "./swiper.css";
import { FreeMode, Pagination, Autoplay, Navigation } from "swiper/modules";
import { Link, useParams } from "react-router";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import villa1type1 from "../../images/map/ahed-type1-first-floor-updated.webp";
import villa2type1 from "../../images/map/ahed-type1-ground-floor-updated.webp";
import villa3type1 from "../../images/map/ahed-type1-penthouse-updated.webp";
import villa1type2 from "../../images/map/FIRST-floor-Type-2.webp";
import villa2type2 from "../../images/map/Ground-floor-Type-2.webp";
import villa3type2 from "../../images/map/Pent-house-Type-2.jpg";
import villa1type3 from "../../images/map/1st Floor Type 3.webp";
import villa2type3 from "../../images/map/GF Type 3.webp";
import villa3type3 from "../../images/map/PH Type 3.jpeg";
import ahedPdf from "../../images/map/ahed.pdf";
import azhaPdf from "../../images/azha-apartment.pdf";
import apt1room2 from "../../images/map/BLock 1- 2 bedrooms.webp";
import apt1room3 from "../../images/map/BLock 1- 3 bedrooms.webp";
import apt2room2 from "../../images/map/block 2 - 2 bedroom.webp";
import apt2room3 from "../../images/map/block 2- 3 bedroom.webp";
import apt3room2 from "../../images/map/Block 3 - 2 bedroom.webp";
import apt3room3 from "../../images/map/Block 3- 3 bedroom.webp";
import apt3room1 from "../../images/map/Block 3 - 1 bedroom.webp";
function Community() {
  const { link } = useParams();
  const [showType1, setShowType1] = useState(false);
  const [showType2, setShowType2] = useState(false);
  const [showType3, setShowType3] = useState(false);
  const [showApt1, setShowApt1] = useState(false);
  const [showApt2, setShowApt2] = useState(false);
  const [showApt3, setShowApt3] = useState(false);
  const selectedData = data.find((datainfo) => datainfo.link === link);
  const [selectedImage, setSelectedImage] = useState(
    selectedData ? selectedData.images[0] : null
  );
  const { t } = useTranslation();

  const modalRef = useRef(null); // Ref for the modal container

  useEffect(() => {
    if (selectedData && selectedData.images.length > 0) {
      setSelectedImage(selectedData.images[0]);
    }
  }, [selectedData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowType1(false);
        setShowType2(false);
        setShowType3(false);
        setShowApt1(false);
        setShowApt2(false);
        setShowApt3(false);
      }
    };

    if (
      showType1 ||
      showType2 ||
      showType3 ||
      showApt1 ||
      showApt2 ||
      showApt3
    ) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showType1, showType2, showType3, showApt1, showApt2, showApt3]);

  if (!selectedData) {
    return <div>The page doesn't exist.</div>;
  }

  const currentLanguageCode = cookies.get("i18next") || "en";

  return (
    <div className="relative">
      {showType1 && (
        <div className="z-[200] animate-zoom-in fixed top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center">
          <div
            className="w-[50%] h-[90%] lg:w-[100%] xl:h-[60%]"
            ref={modalRef}
          >
            <Swiper
              navigation={true}
              modules={[Navigation]}
              centeredSlides={true}
              slidesPerView={1}
              loop={true}
              className="mySwiper"
            >
              <SwiperSlide>
                <img alt="" className="images-villa" src={villa2type1} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="images-villa" src={villa1type1} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="images-villa" src={villa3type1} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      )}

      {showType2 && (
        <div className="z-[200] animate-zoom-in fixed top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center">
          <div
            className="w-[50%] h-[90%] lg:w-[100%] xl:h-[60%]"
            ref={modalRef}
          >
            <Swiper
              navigation={true}
              modules={[Navigation]}
              centeredSlides={true}
              slidesPerView={1}
              loop={true}
              className="mySwiper"
            >
              <SwiperSlide>
                <img alt="" className="images-villa" src={villa2type2} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="images-villa" src={villa1type2} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="images-villa" src={villa3type2} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      )}
      {showType3 && (
        <div className="z-[200] animate-zoom-in fixed top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center">
          <div
            className="w-[50%] h-[90%] lg:w-[100%] xl:h-[60%]"
            ref={modalRef}
          >
            <Swiper
              navigation={true}
              modules={[Navigation]}
              centeredSlides={true}
              slidesPerView={1}
              loop={true}
              className="mySwiper"
            >
              <SwiperSlide>
                <img alt="" className="images-villa" src={villa2type3} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="images-villa" src={villa1type3} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="images-villa" src={villa3type3} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      )}

      {showApt1 && (
        <div className="z-[200] animate-zoom-in fixed top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center">
          <div
            className="w-[50%] h-[90%] lg:w-[100%] xl:h-[60%]"
            ref={modalRef}
          >
            <Swiper
              navigation={true}
              modules={[Navigation]}
              centeredSlides={true}
              slidesPerView={1}
              loop={true}
              className="mySwiper"
            >
              <SwiperSlide>
                <img alt="" className="images-villa" src={apt1room2} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="images-villa" src={apt1room3} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      )}

      {showApt2 && (
        <div className="z-[200] animate-zoom-in fixed top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center">
          <div
            className="w-[50%] h-[90%] lg:w-[100%] xl:h-[60%]"
            ref={modalRef}
          >
            <Swiper
              navigation={true}
              modules={[Navigation]}
              centeredSlides={true}
              slidesPerView={1}
              loop={true}
              className="mySwiper"
            >
              <SwiperSlide>
                <img alt="" className="images-villa" src={apt2room2} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="images-villa" src={apt2room3} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      )}

      {showApt3 && (
        <div className="z-[200] animate-zoom-in fixed top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center">
          <div
            className="w-[50%] h-[90%] lg:w-[100%] xl:h-[60%]"
            ref={modalRef}
          >
            <Swiper
              navigation={true}
              modules={[Navigation]}
              centeredSlides={true}
              slidesPerView={1}
              loop={true}
              className="mySwiper"
            >
              <SwiperSlide>
                <img alt="" className="images-villa" src={apt3room1} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="images-villa" src={apt3room2} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" className="images-villa" src={apt3room3} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      )}

      <div key={selectedData.id}>
        <div className="h-[80vh] lg:h-[auto] relative">
          <video
            className="relative w-full h-full lg:h-[80vh] md:h-[60vh] lg:mt-[5%] object-cover z-0"
            autoPlay
            muted
            loop
          >
            <source src={selectedData.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div
          className={`w-[100%] lg:flex-col flex p-[5%] ${
            currentLanguageCode === "ar" && "flex-row-reverse text-right"
          }`}
        >
          <div className="w-[50%] flex flex-col justify-between lg:w-[100%] p-[3%]">
            <h1 className="text-[2.5vw] lg:text-[30px] sm:text-[25px] font-[cairo]">
              {currentLanguageCode === "ar"
                ? selectedData.title_ar
                : selectedData.title}
            </h1>

            <div
              className={`text-[18px] flex flex-col gap-[10px] py-[3%] ${
                currentLanguageCode === "ar" ? "text-right" : "text-justify"
              }`}
              dangerouslySetInnerHTML={{
                __html:
                  currentLanguageCode === "ar"
                    ? selectedData.body_ar
                    : selectedData.body,
              }}
            />
            {selectedData.link === "azha" && (
              <>
                <div className="animate-bounce md:my-[5%] ">
                  <a
                    href={azhaPdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mb-[2%] w-[fit-content] font-bold text-[18px]  hover:text-[gray] hover:underline cursor-pointer"
                  >
                    {t("navbar.community.brochure")}
                  </a>
                </div>
                <div
                  className={` mt-[5%] ${
                    currentLanguageCode === "ar" &&
                    "justify-end flex-row-reverse"
                  } flex justify-between xl:justify-between lg:items-center lg:flex-col lg:gap-[15px]  xl:text-[12px] `}
                >
                  <button
                    onClick={() => setShowApt1(true)}
                    class="btn w-[10vw] xl:w-[12vw] lg:w-[100%]  py-[1%] xl:text-[1.1vw] lg:text-[12px] lg:p-[10px] text-[12px]"
                  >
                    {" "}
                    {t("navbar.community.apt1")}
                  </button>
                  <button
                    onClick={() => setShowApt2(true)}
                    class="btn w-[10vw] xl:w-[12vw] lg:w-[100%]  py-[1%] xl:text-[1.1vw] lg:text-[12px] lg:p-[10px] text-[12px]"
                  >
                    {" "}
                    {t("navbar.community.apt2")}
                  </button>
                  <button
                    onClick={() => setShowApt3(true)}
                    class="btn w-[10vw] xl:w-[12vw] lg:w-[100%]  py-[1%] xl:text-[1.1vw] lg:text-[12px] lg:p-[10px] text-[12px]"
                  >
                    {" "}
                    {t("navbar.community.apt3")}
                  </button>
                </div>
              </>
            )}
            {selectedData.link === "ahed" && (
              <div className="animate-bounce md:my-[5%] ">
                <a
                  rel="noopener noreferrer"
                  href={ahedPdf}
                  target="_blank"
                  className="mb-[2%] w-[fit-content] font-bold text-[18px]  hover:text-[gray] hover:underline cursor-pointer"
                >
                  {t("navbar.community.brochure")}
                </a>
              </div>
            )}

            {selectedData.link === "ahed" && (
              <div
                className={` mt-[5%] ${
                  currentLanguageCode === "ar" && "justify-end flex-row-reverse"
                } flex justify-between xl:justify-between lg:flex-col lg:gap-[15px]  xl:text-[12px] `}
              >
                <button
                  onClick={() => setShowType1(true)}
                  class="btn py-[10px] px-[12px] xl:p-[8px] xl:text-[1.1vw] lg:text-[12px] lg:p-[10px] text-[12px]"
                >
                  {" "}
                  {t("navbar.community.type1")}
                </button>
                <button
                  onClick={() => setShowType2(true)}
                  class="btn py-[10px] px-[12px] xl:p-[8px] xl:text-[1.1vw] lg:text-[12px] lg:p-[10px] text-[12px]"
                >
                  {" "}
                  {t("navbar.community.type2")}
                </button>
                <button
                  onClick={() => setShowType3(true)}
                  class="btn py-[10px] px-[12px] xl:p-[8px] xl:text-[1.1vw] lg:text-[12px] lg:p-[10px] text-[12px]"
                >
                  {" "}
                  {t("navbar.community.type3")}
                </button>
              </div>
            )}
          </div>

          <div className="w-[50%] flex justify-center items-center lg:w-[100%]">
            <div className="h-[100%] lg:w-[100%] p-[3%]">
              <div className="w-[40vw] lg:w-[100%]">
                {/* Selected Image Display */}
                <div className="h-[40vh] md:hidden pb-[2%]">
                  <img
                    alt="Selected"
                    src={selectedImage}
                    className="w-[100%] object-cover h-[100%]"
                  />
                </div>

                <div className="h-[200px]">
                  <Swiper
                    loop={true}
                    navigation={true}
                    breakpoints={{
                      1280: {
                        slidesPerView: 3,
                      },
                      600: {
                        slidesPerView: 2,
                      },
                    }}
                    spaceBetween={30}
                    freeMode={true}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: true,
                    }}
                    modules={[FreeMode, Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {selectedData.images.map((image, index) => (
                      <SwiperSlide key={index}>
                        <img
                          onClick={() => setSelectedImage(image)}
                          alt="azha"
                          src={image}
                          className="w-[100%] h-[100%] object-cover"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="w-[100%] pb-[5%] flex justify-center items-center h-[auto]">
          <div className="w-[60vw] xl:w-[90vw] h-[80vh] md:h-[50vh]">
            <iframe
              title="Virtual Tour"
              className="h-[100%] w-[100%]"
              sandbox="allow-scripts allow-same-origin"
              src={selectedData.iframe}
            ></iframe>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Community;
