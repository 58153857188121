import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "ar"],
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },

    fallbackLng: "en",
    detection: {
      order: ["path","cookie", "htmlTag"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

  const setDirection = (lng) => {
    const htmlElement = document.documentElement;
    if (lng === "ar") {
     
      htmlElement.setAttribute("dir", "rtl");
      document.title = "واحة الصاروج ";
    } else {
      htmlElement.setAttribute("dir", "ltr");
      document.title = "Sarooj Oasis";
    }
  };

  // Set the initial direction
  setDirection(i18next.language);

  // Listen for language changes and update direction
  i18next.on("languageChanged", (lng) => {
    setDirection(lng);
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  </BrowserRouter>
);
