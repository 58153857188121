import "./App.css";
import { Link, Route, Routes } from "react-router";
import ComingSoon from "./Pages/ComingSoon";
import Home from "./Pages/HomePage";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import ContactUs from "./Pages/ContactUsPage";
import Loader from "./Components/Loader/Loader";
import { useState, useEffect } from "react";
import Community from "./Components/Community/Community";
import OurCommunityPage from "./Pages/OurCommunityPage";
import NewsAndEvents from "./Pages/NewsAndEvents";
import { ImWhatsapp } from "react-icons/im";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

function App() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [showContact, setShowContact] = useState(false);
  const currentLanguageCode = cookies.get("i18next") || "en";
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
      <>
        <Navbar />
        <Routes>
          <Route path="/coming-soon" Component={NewsAndEvents} />
          <Route path="/" exact Component={Home} />
          <Route path="/contact-us" Component={ContactUs} />
          <Route path="/our-community" Component={OurCommunityPage} />

          <Route path="/our-community/:link" Component={Community} />
        </Routes>
        <div
          className={`bg-[#000000d3] text-center fixed text-white right-[3%] w-[140px] p-[0.5%] transition-opacity duration-500 transform ${
            showContact
              ? "opacity-100 top-[81%] translate-y-0"
              : "opacity-0  translate-y-2"
          }`}
        >
          <p>{t("navbar.reach-out")}</p>
          <p>{t("navbar.lets-chat")}</p>
        </div>

        <div
          className={`flex justify-end  ${
            currentLanguageCode === "ar" ? "left-0" : "right-0"
          }  font-[cairo] font-[100] fixed top-[90%] right-[5%] lg:right-0 lg:px-[5%] z-[50] `}
        >
          <a
            onMouseOver={() => setShowContact(true)}
            onMouseLeave={() => setShowContact(false)}
            href="https://wa.me/96879000997"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[green]  bounce border-[1px] p-[10px] w-[fit-content] rounded-[15px]"
          >
            <ImWhatsapp className="w-[30px] text-[white] h-[30px]" />
          </a>
        </div>
        <Footer />
      </>
       )} 
    </div>
  );
}

export default App;
