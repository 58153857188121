import landingPage from "../../images/map/background-main.webp";
import phonePage from "../../images/map/map-phone.webp";
import interactivemap from "./interactivemap.js";
import { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import block1Phone from "../../images/map/block1-phone.png";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import "swiper/css";
import "swiper/css/navigation";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import { Navigation } from "swiper/modules";
import commercialPin from "../../images/map/icons/business_center.webp";
import townHousePin from "../../images/map/icons/ahed-villas-white.webp";
import thurayaPin_ar from "../../images/map/icons/thuraya_ar.png";
import ahed_ar from "../../images/map/icons/ahed_ar.png";
import azha1_ar from "../../images/map/icons/azha1_ar.png";
import azha2_ar from "../../images/map/icons/azha2_ar.png";
import azha3_ar from "../../images/map/icons/azha3_ar.png";
import thurayaPin from "../../images/map/icons/althuraya_pin.webp";
import townZoomIn from "../../images/map/town-zoom.webp";
import blockPin1 from "../../images/map/icons/azha-apartment-white-1.webp";
import blockPin2 from "../../images/map/icons/azha-apartment-white-2.webp";
import blockPin3 from "../../images/map/icons/azha-apartment-white-3.webp";
import block1layout from "../../images/map/block1-typical-layout-B&W.webp";
import block2layout from "../../images/map/block2-typical-layout-B&W.webp";
import block3layout from "../../images/map/block3-typical-layout-B&W.png";
import ahedpdf from "../../images/map/ahed.pdf";
import communityMap from "./communitymap.js";
import bedroom from "../../images/map/icons/bedroon-icon.webp";
import house from "../../images/map/icons/home-icon.webp";
import plotSize from "../../images/map/icons/plot-size-icon.png";
import gfaTotal from "../../images/map/icons/total-gfa-icon.png";
import gfa from "../../images/map/icons/gfa-icon.webp";
import plazaPin from "../../images/map/icons/plaza_pin.png";
import block4Pin from "../../images/map/icons/azha_block_4.webp";
import plaza from "../../images/videos/plaza.mp4";
import pin from "../../images/map/icons/pin.webp";
import thurayaVideo from "../../images/videos/thuraya-video.mp4";
import businessCenter from "../../images/videos/business-center-video.mp4";
import plaza_ar from "../../images/map/icons/plaza_ar.png"
import azha4_ar from "../../images/map/icons/azha4_ar.png"
import azhaPdf from "../../images/azha-apartment.pdf";
import business_ar from "../../images/map/icons/businessCenter_ar.png"
function CommunityMap() {
  const [showBusinessCenter, setShowBusinessCenter] = useState(false);
  const [showTownHouses, setShowTownHouses] = useState(false);
  const [showResidential, setShowResidential] = useState(false);
  const [showVillas, setShowVillas] = useState(false);
  const [townZoom, setTownZoom] = useState(false);
  const [showBlock1, setShowBlock1] = useState(false);
  const [showBlock2, setShowBlock2] = useState(false);
  const [showBlock3, setShowBlock3] = useState(false);
  const [hoveredVilla, setHoveredVilla] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState("F1");
  const floorData = communityMap[0].floorData;
  const floorData2 = communityMap[1]?.floorData2; // Optional chaining in case it's missing
  const floorData3 = communityMap[2]?.floorData3;
  const [openPlaza, setOpenPlaza] = useState(false);
  const [openThuraya, setOpenThuraya] = useState(false);
  const [showLocations, setShowLocations] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(false);
  const handleFloorClick = (floor) => {
    setSelectedFloor(floor); // Set the clicked floor as selected
  };
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const floors = ["F6", "F5", "F4", "F3", "F2", "F1"];
  const [activeIndex, setActiveIndex] = useState(0);

  const floors1 = Object.keys(floorData).filter(
    (floor) => floorData[floor] && floorData[floor].length > 0
  );
  const floors3 = Object.keys(floorData3).filter(
    (floor) => floorData3[floor] && floorData3[floor].length > 0
  );

  // const handleNextClick = () => {
  //   if (activeIndex < floors1.length - 4) {
  //     setActiveIndex((prevIndex) => prevIndex + 1);
  //   }
  // };

  // const handlePreviousClick = () => {
  //   if (activeIndex > 0) {
  //     setActiveIndex((prevIndex) => prevIndex - 1);
  //   }
  // };

  const handleGlobalClick = () => {
    if (showVillas) setShowVillas(false);
    if (showTownHouses) setShowTownHouses(false);
    if (showResidential) setShowResidential(false);
    if (townZoom) setTownZoom(false);
    if (openPlaza) setOpenPlaza(false);
    if (openThuraya) setOpenThuraya(false);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth state when window is resized
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="w-[100%] overflow-hidden  relative">
      <div className="  flex justify-center items-center h-[100vh]  xl:h-[85vh] lg:h-[55vh] md:h-[80vh] sm:h-[75vh] relative">
        <div
          className="absolute inset-0"
          onClick={() => {
            handleGlobalClick();
          }}
        >
          <img
            className="w-full h-[100%] md:hidden relative  "
            src={landingPage}
            alt="Sarooj landing page"
          />{" "}
          <img
            className="w-full hidden md:flex object-cover relative h-[100%]  "
            src={phonePage}
            alt="Sarooj landing page"
          />
        </div>
        <img
          alt=""
          onClick={(e) => setTownZoom(true)}
          src={currentLanguageCode === "ar" ? ahed_ar : townHousePin}
          className={` ${
            currentLanguageCode === "ar"
              ? "w-[60px] left-[18%] xl:w-[50px]"
              : "w-[100px] xl:w-[80px] left-[16%]"
          }  lg:hidden cursor-pointer bounce   top-[24%]   absolute`}
        />
        <img
          onClick={(e) => setShowBlock1(true)}
          className={` ${
            currentLanguageCode === "ar"
              ? "w-[60px] left-[42%] xl:w-[50px]"
              : "w-[120px] xl:w-[100px] left-[41%] xl:left-[40%]"
          } cursor-pointer lg:hidden   top-[35%]  bounce absolute`}
          alt=""
          src={currentLanguageCode === "ar" ? azha1_ar : blockPin1}
        />
        <img
          onClick={(e) => setShowBlock2(true)}
          className={` ${
            currentLanguageCode === "ar"
              ? "w-[60px] left-[49%] xl:w-[50px]"
              : "w-[120px] xl:w-[100px] xl:left-[46%] left-[47%]"
          } cursor-pointer lg:hidden   top-[39%]   bounce absolute`}
          alt=""
          src={currentLanguageCode === "ar" ? azha2_ar : blockPin2}
        />
        <img
          onClick={(e) => setShowBlock3(true)}
          className={` ${
            currentLanguageCode === "ar"
              ? "w-[60px] left-[56%] xl:w-[50px]"
              : "w-[120px] xl:w-[100px] left-[55%] xl:left-[53%]"
          }  cursor-pointer  lg:hidden  top-[40%]  bounce absolute`}
          alt=""
          src={currentLanguageCode === "ar" ? azha3_ar : blockPin3}
        />
        {openPlaza && (
          <div
            onClick={() => {
              setOpenPlaza(false);
            }}
            className="z-[200] animate-zoom-in top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center"
          >
            <div>
              <div className="h-[80vh] relative flex justify-center items-center">
                <video className="relative w-[80%] object-cover z-0" controls>
                  <source src={plaza} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        )}

        {showBusinessCenter && (
          <div
            onClick={() => {
              setShowBusinessCenter(false);
            }}
            className="z-[200] animate-zoom-in top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center"
          >
            <div>
              <div className="h-[80vh] relative flex justify-center items-center">
                <video
                  className="relative w-[50%] lg:w-[80%]  object-cover z-0"
                  controls
                >
                  <source src={businessCenter} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        )}

        {openThuraya && (
          <div
            onClick={() => {
              setOpenThuraya(false);
            }}
            className="z-[200] animate-zoom-in top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center"
          >
            <div>
              <div className="h-[80vh] relative flex justify-center items-center">
                <video
                  className="relative w-[50%] lg:w-[80%] object-cover z-0"
                  controls
                >
                  <source src={thurayaVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        )}
        {showBlock1 && (
          <div
            onClick={() => {
              setShowBlock1(false);
              setSelectedFloor(null);
            }}
            className="z-[200] animate-zoom-in  top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="h-[80%] w-[80%] lg:h-[90%] md:h-[80%] sm:h-[55%] md:w-[100%] lg:w-[95%]  md:flex-col  xl:w-[90%] xl:h-[75%] bg-[white] md:justify-center flex justify-evenly relative"
            >
              <div className="h-[100%] md:items-center md:h-[75%]  lg:pl-[2%] md:pl-[0%] flex justify-center md:justify-end  font-[cairo]">
                <img
                  className=" relative h-full md:hidden"
                  alt="Block 1"
                  src={block1layout}
                />
                <img
                  className=" relative h-full md:flex md md:w-[100vw]  hidden"
                  alt="Block 1"
                  src={block1Phone}
                />

                <>
                  {floorData[selectedFloor]?.map((data, index) => (
                    <div
                      key={index}
                      className="xl:text-[1.1vw]  lg:text-[8.5px] sm:text-[7px] text-[13px] justify-center items-center flex flex-col gap-[5px] absolute text-center"
                      style={{
                        ...(windowWidth >= 600 && {
                          top: data.top,
                          bottom: data.bottom,
                          left: data.left,
                          right: data.right,
                          height: data.height,
                          width: data.width,
                          backgroundColor: selectedUnit === index && data.bg,
                        }),
                        ...(windowWidth <= 600 && {
                          top: data.topXl,
                          left: data.leftXl,
                          height: data.heightXl,
                          width: data.widthXl,
                          backgroundColor: selectedUnit === index && data.bg,
                        }),
                      }}
                      onClick={() => setSelectedUnit(index)}
                    >
                      <p
                        className="border  w-[fit-content] px-[5%] border-[black]"
                        style={{ backgroundColor: data.bgColor }}
                      >
                        {data.unit}
                      </p>
                      <p>{data.room}</p>
                    </div>
                  ))}
                </>
              </div>
              <div className="flex mt-[50px] md:flex-col lg:mt-[1%] h-[fit-content] md:h-[100px] sm:h-[80px] md:gap-[10px] md:justify-start md:items-start md:w-[100%]  flex-col gap-[5px] rounded-[10px] md:rounded-[5px] md:px-[1%] w-[80px] py-[0.5%] font-[cairo] bg-[#2c2c2c] text-white justify-center items-center">
                <p className="text-center w-[100%]">Floors</p>
                <div className="flex flex-col justify-center items-center w-[100%] md:flex-row">
                  <div
                    // onClick={handlePreviousClick}
                    className={`bg-[#222222] rounded-t-[20px] md:rotate-[-90deg] md:w-[50px] text-white w-[90%] h-[fit-content] flex justify-center items-center text-center cursor-not-allowed`}
                  >
                    <MdOutlineArrowDropUp className="text-[30px]" />
                  </div>

                  {/* Floors Display */}
                  <div className="flex flex-col md:flex-row gap-[10px] justify-evenly py-[10%] md:py-[0%] transition-all duration-500 ease-in-out">
                    {floors1
                      // .slice(activeIndex, activeIndex + 5)
                      .map((floor, index) => (
                        <p
                          key={index}
                          className={`h-[50px] rounded-[5px] cursor-pointer w-[50px] sm:w-[30px] sm:h-[30px] border text-center flex justify-center items-center ${
                            selectedFloor === floor
                              ? "bg-[gray]"
                              : "bg-[#222222]"
                          } hover:bg-[gray] transition-all duration-500 ease-in-out`}
                          onClick={() => handleFloorClick(floor)}
                        >
                          {floor}
                        </p>
                      ))}
                  </div>
                  {/* Down Arrow */}
                  <div
                    // onClick={handleNextClick}
                    className={`bg-[#222222] rounded-b-[20px] text-white w-[90%] md:rotate-[-90deg] md:w-[50px] text-center flex justify-center items-center text-[50px] cursor-not-allowed`}
                  >
                    <MdOutlineArrowDropDown className="text-[30px]" />
                  </div>
                </div>
              </div>
              <div className=" md:text-[12px] flex justify-end items-end  gap-[2%] lg:flex-col lg:items-center md:text-end px-[1.5%] absolute w-[100%] top-[10px] lg:justify-center md:justify-end">
                <div className="animate-bounce md:my-[2%] w-[100%] lg:text-center md:text-end text-end ">
                  <a
                    href={azhaPdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-[fit-content] font-bold text-[18px] lg:text-[12px]  hover:text-[gray] hover:underline cursor-pointer"
                  >
                    Download Our brochure
                  </a>
                </div>
                <div className="flex gap-[5px]  justify-end md:w-[100%] ">
                  <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#ff0000a9]">
                    Booked
                  </p>
                  <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#008000b4]">
                    Available
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {showBlock2 && (
          <div
            onClick={() => {
              setShowBlock2(false);
              setSelectedFloor(null);
            }}
            className="z-[200] animate-zoom-in  top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="h-[80%] w-[80%] lg:h-[90%] md:h-[80%] sm:h-[55%] md:w-[100%] lg:w-[95%]  md:flex-col  xl:w-[90%] xl:h-[75%] bg-[white] md:justify-center flex justify-evenly relative"
            >
              <div className="h-[100%] md:items-center md:h-[75%]  lg:pl-[2%] md:pl-[0%] flex justify-center md:justify-end  font-[cairo]">
                <img
                  className=" relative h-full "
                  alt="Block 2"
                  src={block2layout}
                />

                <>
                  {floorData2[selectedFloor]?.map((data, index) => (
                    <div
                      key={index}
                      className="xl:text-[1.1vw] lg:text-[8.5px] sm:text-[7px] text-[13px] justify-center items-center flex flex-col gap-[5px] absolute text-center"
                      style={{
                        ...(windowWidth >= 600 && {
                          top: data.top,
                          bottom: data.bottom,
                          left: data.left,
                          right: data.right,
                          height: data.height,
                          width: data.width,
                          backgroundColor: selectedUnit === index && data.bg,
                        }),
                        ...(windowWidth <= 600 && {
                          top: data.topXl,
                          left: data.leftXl,
                          height: data.heightXl,
                          width: data.widthXl,
                          backgroundColor: selectedUnit === index && data.bg,
                        }),
                      }}
                      onClick={() => setSelectedUnit(index)}
                    >
                      <p
                        className="border w-[fit-content] px-[5%] border-[black]"
                        style={{ backgroundColor: data.bgColor }}
                      >
                        {data.unit}
                      </p>
                      <p>{data.room}</p>
                    </div>
                  ))}
                </>
              </div>
              <div className="flex mt-[50px] md:flex-col lg:mt-[1%] h-[fit-content] md:h-[100px] sm:h-[80px] md:gap-[10px] md:justify-start md:items-start md:w-[100%]  flex-col gap-[5px] rounded-[10px] md:rounded-[5px] md:px-[1%] w-[80px] py-[0.5%] font-[cairo] bg-[#2c2c2c] text-white justify-center items-center">
                <p className="text-center w-[100%]">Floors</p>

                <div className="flex flex-col justify-center items-center w-[100%] md:flex-row">
                  <div
                    className={`bg-[#222222] rounded-t-[20px] md:rotate-[-90deg] md:w-[50px] text-white w-[90%] h-[fit-content] flex justify-center items-center text-center cursor-not-allowed`}
                  >
                    <MdOutlineArrowDropUp className="text-[30px]" />
                  </div>
                  <div className="flex flex-col md:flex-row gap-[10px] justify-evenly py-[10%] md:py-[0%] transition-all duration-500 ease-in-out">
                    {floors
                      .slice(activeIndex, activeIndex + 6)
                      .map((floor, index) => (
                        <p
                          key={index}
                          className={`h-[50px] rounded-[5px] cursor-pointer w-[50px] sm:w-[30px] sm:h-[30px] border text-center flex justify-center items-center ${
                            selectedFloor === floor
                              ? "bg-[gray]"
                              : "bg-[#222222]"
                          } hover:bg-[gray] transition-all duration-500 ease-in-out`}
                          onClick={() => handleFloorClick(floor)} // Set floor as selected on click
                        >
                          {floor}
                        </p>
                      ))}
                  </div>
                  <div
                    className={`bg-[#222222] rounded-b-[20px] text-white w-[90%] md:rotate-[-90deg] md:w-[50px] text-center flex justify-center items-center text-[50px] cursor-not-allowed`}
                  >
                    <MdOutlineArrowDropDown className="text-[30px]" />
                  </div>
                </div>
              </div>
              <div className=" md:text-[12px] flex justify-end items-end lg:justify-center  gap-[2%] lg:items-center md:text-end px-[1.5%] absolute w-[100%] top-[10px] md:justify-end">
                <div className="animate-bounce md:my-[1%]  md:text-end text-end ">
                  <a
                    href={azhaPdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-[fit-content] font-bold text-[18px] lg:text-[12px]  hover:text-[gray] hover:underline cursor-pointer"
                  >
                    Download Our brochure
                  </a>
                </div>
                <div className="flex gap-[5px]  justify-end ">
                  <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#ff0000a9]">
                    Booked
                  </p>
                  <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#008000b4]">
                    Available
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {showBlock3 && (
          <div
            onClick={() => {
              setShowBlock3(false);
              setSelectedFloor(null);
            }}
            className="z-[200] animate-zoom-in  top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="h-[80%] w-[80%] lg:h-[90%] md:h-[80%] sm:h-[55%] md:w-[100%] lg:w-[95%]  md:flex-col  xl:w-[90%]  bg-[white] md:justify-center flex justify-evenly relative"
            >
              <div className="h-[100%] md:items-center md:h-[75%]  md:justify-center  lg:pl-[2%] md:pl-[0%] flex justify-center font-[cairo]">
                <img
                  className=" relative w-[100%] lg:w-[80%] h-full"
                  alt="Block 3"
                  src={block3layout}
                />

                <>
                  {floorData3[selectedFloor]?.map((data, index) => (
                    <div
                      key={index}
                      className="xl:text-[1.1vw] lg:text-[8.5px] sm:text-[7px] text-[13px] justify-center items-center flex flex-col gap-[5px] absolute text-center"
                      style={{
                        ...(windowWidth >= 600 && {
                          top: data.top,
                          bottom: data.bottom,
                          left: data.left,
                          right: data.right,
                          height: data.height,
                          width: data.width,
                          backgroundColor: selectedUnit === index && data.bg,
                        }),
                        ...(windowWidth <= 600 && {
                          top: data.topXl,
                          left: data.leftXl,
                          height: data.heightXl,
                          width: data.widthXl,
                          backgroundColor: selectedUnit === index && data.bg,
                        }),
                      }}
                      onClick={() => setSelectedUnit(index)}
                    >
                      <p
                        className="border w-[fit-content] px-[5%] border-[black]"
                        style={{ backgroundColor: data.bgColor }}
                      >
                        {data.unit}
                      </p>
                      <p>{data.room}</p>
                    </div>
                  ))}
                </>
              </div>
              <div className="flex mt-[50px] md:flex-col lg:mt-[1%] h-[fit-content] md:h-[100px] sm:h-[80px] md:gap-[10px] md:justify-start md:items-start md:w-[100%]  flex-col gap-[5px] rounded-[10px] md:rounded-[5px] md:px-[1%] w-[80px] py-[0.5%] font-[cairo] bg-[#2c2c2c] text-white justify-center items-center">
                <p className="text-center w-[100%]">Floors</p>

                <div className="flex flex-col justify-center items-center w-[100%] md:flex-row">
                  {" "}
                  <div
                    className={`bg-[#222222] rounded-t-[20px] md:rotate-[-90deg] md:w-[50px] text-white w-[90%] h-[fit-content] flex justify-center items-center text-center cursor-not-allowed`}
                  >
                    <MdOutlineArrowDropUp className="text-[30px]" />
                  </div>
                  <div className="flex flex-col md:flex-row gap-[10px] justify-evenly py-[10%] md:py-[0%] transition-all duration-500 ease-in-out">
                    {floors3
                      .slice(activeIndex, activeIndex + 5)
                      .map((floor, index) => (
                        <p
                          key={index}
                          className={`h-[50px] rounded-[5px] cursor-pointer w-[50px] sm:w-[30px] sm:h-[30px] border text-center flex justify-center items-center ${
                            selectedFloor === floor
                              ? "bg-[gray]"
                              : "bg-[#222222]"
                          } hover:bg-[gray] transition-all duration-500 ease-in-out`}
                          onClick={() => handleFloorClick(floor)} // Set floor as selected on click
                        >
                          {floor}
                        </p>
                      ))}
                  </div>
                  <div
                    className={`bg-[#222222] rounded-b-[20px] text-white w-[90%] md:rotate-[-90deg] md:w-[50px] text-center flex justify-center items-center text-[50px] cursor-not-allowed`}
                  >
                    <MdOutlineArrowDropDown className="text-[30px]" />
                  </div>
                </div>
              </div>

              <div className=" md:text-[12px] flex  gap-[2%] flex-col py-[2%] justify-start  px-[1.5%] absolute w-[100%] top-[10px] lg:justify-center md:justify-end">
                <div className="animate-bounce md:my-[2%] ">
                  <a
                    href={azhaPdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-[fit-content] font-bold text-[18px] lg:text-[12px]  hover:text-[gray] hover:underline cursor-pointer"
                  >
                    Download Our brochure
                  </a>
                </div>
                <div className=" md:text-[12px] flex  gap-[5px] top-[10px]">
                  <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#ff0000a9]">
                    Booked
                  </p>
                  <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#008000b4]">
                    Available
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <img
          alt=""
          onClick={() => setShowBusinessCenter(true)}
          src={currentLanguageCode === "ar" ? business_ar : commercialPin}
          className={`${
            currentLanguageCode === "ar"
              ? "w-[60px] right-[6%] xl:w-[50px]"
              : "w-[59px] xl:w-[50px] right-[6%] lg:left-[6%]"
          }  lg:hidden  cursor-pointer bounce   top-[57%]  absolute `}
        />

        <img
          alt=""
          onClick={() => setOpenThuraya(true)}
          src={currentLanguageCode === "ar" ? thurayaPin_ar : thurayaPin}
          className={` ${
            currentLanguageCode === "ar"
              ? "w-[60px] right-[63%] xl:w-[50px]"
              : "w-[59px] xl:w-[50px] right-[63%]"
          }  cursor-pointer lg:hidden  bounce   top-[57%]   absolute`}
        />

        <img
          onClick={() => setOpenPlaza(true)}
          alt=""
          src={currentLanguageCode === "ar" ? plaza_ar : plazaPin}
          className={` ${
            currentLanguageCode === "ar"
              ? "w-[60px] right-[61%] xl:w-[50px]"
              : "w-[59px] xl:w-[50px] right-[61%]"
          } cursor-pointer lg:hidden  bounce   top-[35%]  absolute`}
        />
        <img
          alt=""
          src={currentLanguageCode === "ar" ? azha4_ar : block4Pin}
          className={` ${
            currentLanguageCode === "ar"
              ? "w-[60px] right-[34%] xl:right-[33%] xl:w-[50px]"
              : "w-[59px] xl:w-[50px] right-[34%] xl:right-[33%]"
          }   lg:hidden  bounce   top-[43%]   absolute`}
        />
        <div className="absolute hidden lg:flex justify-center items-center top-[30%] right-[0px] ">
          <div
            className={`absolute top-[30%] right-[0px] flex items-center transform transition-transform duration-500 ease-in-out ${
              showLocations ? "translate-x-0" : "translate-x-40"
            }`}
          >
            <div
              className="bg-[#202020c2] rounded-full w-[50px] bounce h-[50px] flex justify-center items-center cursor-pointer"
              onClick={() => setShowLocations(!showLocations)} // Toggle state on click
            >
              <img className="w-[25px]" src={pin} alt="pin" />
            </div>

            {/* Animated location names div */}
            <div className="bg-[#202020c2] w-[160px]  h-[150px] rounded-[10px] flex flex-col justify-evenly px-[5%] text-white text-[12px] ml-4">
              <p
                className="underline w-[fit-content]"
                onClick={() => setTownZoom(true)}
              >
                Ahed Villas
              </p>
              <p
                className="underline w-[fit-content]"
                onClick={() => setOpenPlaza(true)}
              >
                Plaza
              </p>
              <p
                className="underline w-[fit-content]"
                onClick={() => setShowBlock1(true)}
              >
                Azha Apartment Block 1
              </p>
              <p
                className="underline w-[fit-content]"
                onClick={() => setShowBlock2(true)}
              >
                Azha Apartment Block 2
              </p>
              <p
                className="underline w-[fit-content]"
                onClick={() => setShowBlock3(true)}
              >
                Azha Apartment Block 3
              </p>
              <p
                className="underline w-[fit-content]"
                onClick={() => setOpenThuraya(true)}
              >
                Althuraya Villas
              </p>
              <p
                className="underline w-[fit-content]"
                onClick={() => setShowBusinessCenter(true)}
              >
                Business Center
              </p>
            </div>
          </div>
        </div>

        {townZoom && (
          <div
            onClick={() => setTownZoom(false)}
            className="h-[100%] w-[100%] flex items-center justify-center z-[100] absolute inset-0 backdrop-blur-md bg-white/30 rounded-xl border border-white/20"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="h-[100%] w-[60%] xl:w-[70%] md:h-[65%] lg:w-[70%] md:w-[90%] sm:w-[100%]  animate-zoom-in relative"
            >
              <img
                src={townZoomIn}
                alt=""
                className="h-[100%] w-[100%]  shadow-[0_4px_15px_rgba(0,0,0,0.3)]"
              />
              <a
                download
                href={ahedpdf}
                className="absolute top-[3%] font-bold text-[25px] animate-bounce lg:text-[12px] md:text-[10px] right-[2%] hover:text-[#a1a1a1] text-white underline underline-offset-2"
              >
                Download our brochure!
              </a>
              {interactivemap.map((villa) => (
                <div
                  key={villa.id}
                  onMouseOver={() => setHoveredVilla(villa.id)}
                  onMouseLeave={() => setHoveredVilla(null)}
                  className={`text-[9px] 2xl:text-[7.5px] flex justify-center items-center flex-col text-white absolute w-[3.2vw] xl:w-[3.8vw] lg:w-[5vw] lg:h-[4.9vw] md:h-[5vw] sm:h-[7vw] h-[2.8vw] 2xl:h-[3.3vw] xl:h-[3.6vw] transform opacity-0 transition-opacity duration-300 hover:opacity-100`}
                  style={{
                    backgroundColor: villa.color,
                    transform: `rotate(${villa.rotation})`,
                    left: villa.left,
                    top: villa.top,
                  }}
                ></div>
              ))}

              {hoveredVilla &&
                interactivemap
                  .filter((villa) => villa.id === hoveredVilla)
                  .map((villa) => (
                    <div
                      key={villa.id}
                      className="bg-[#151515] w-[30%]  rounded-[5px] text-white flex flex-col gap-[10px] lg:gap-[5px] text-[13px] 2xl:text-[0.8vw] lg:text-[9.5px] md:text-[8.5px]  xl:text-[1vw] px-[2%] py-[1%] absolute top-[1%] lg:w-[40%] right-[1%] transition-opacity duration-300 hover:opacity-100"
                    >
                      <div className="flex justify-between">
                        <div className="leading-4">
                          {/* <h2>VILLA NUMBER:</h2> */}
                          {/* <h2>Villa # {villa.villa_number}</h2> */}
                        </div>
                        <p
                          className={`${
                            villa.status === "BOOKED"
                              ? "bg-[red]"
                              : "bg-[green]"
                          } text-center h-[fit-content] text-[12px] md:text-[8px] w-[fit-content] p-[2%] rounded-[5px]`}
                        >
                          {villa.status}
                        </p>
                      </div>
                      <div className="font-[cairo] flex flex-col gap-[10px] lg:gap-[5px]">
                        {/* <p>PLOT SIZE SQM: {villa.plot_size_sqm}</p>
                        <p>GFA SQM: {villa.gfa_sqm}</p>
                        <p>FLOORS: {villa.floors}</p>
                        <p>GFA SQM WITH PH: {villa.gfa_sqm_with_ph}</p>
                        <p>FLOORS: {villa.floors_2}</p>
                        <p>NO. OF ROOMS: {villa.number_of_rooms}</p>
                        <p>TYPE: {villa.type}</p> */}
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[20px] w-[20px] lg:w-[15px] lg:h-[15px] md:w-[12px] md:h-[12px]"
                            src={house}
                            alt="house-icon"
                          />
                          <h2>
                            Villa #{villa.villa_number} Type {villa.typeNumber}{" "}
                            {villa.type}
                          </h2>
                        </div>
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[25px] w-[25px] lg:w-[18px] lg:h-[18px] md:w-[15px] md:h-[15px]"
                            src={plotSize}
                            alt="house-icon"
                          />
                          <p>Plot Size {villa.plot_size_sqm} sqm</p>
                        </div>
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[25px] w-[25px] lg:w-[18px] lg:h-[18px] md:w-[15px] md:h-[15px]"
                            src={bedroom}
                            alt="bedroom"
                          />
                          <p>{villa.number_of_rooms}</p>
                        </div>
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[25px] w-[25px] lg:w-[18px] lg:h-[18px] md:w-[15px] md:h-[15px]"
                            src={gfa}
                            alt="gfa"
                          />
                          <p>GFA {villa.gfa_sqm} sqm</p>
                        </div>
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[25px] w-[25px] lg:w-[18px] lg:h-[18px] md:w-[15px] md:h-[15px]"
                            src={gfaTotal}
                            alt="gfa"
                          />
                          <p>GFA Extendable to {villa.gfa_sqm_with_ph} sqm</p>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CommunityMap;
