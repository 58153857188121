import React from "react";
import Amenities from "../Components/Community/Amenities";
import CommunityMap from "../Components/Community/CommunityMap";

function OurCommunityPage() {
  return (
    <div className="h-[auto]">
      <CommunityMap />
      <Amenities/>
    </div>
  );
}

export default OurCommunityPage;
